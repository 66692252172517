@import "./variables";

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap.scss';



html,
body,
#root {
    height: 100%;
}

.btn {

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

a {

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.auth{
	.auth-background-holder {
		height: 100vh;
		min-height: 100%;
	}
}

.auth-main-col, .auth-background-col{
	position: relative;
}

.auth-main-col {
	margin: auto;
}

.auth-background-col {
	background: rgba($theme-text-color-primary, 0.6);
}

.auth-background-mask {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.1);
	background-image: url("https://images.unsplash.com/photo-1527694224012-be005121c774?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2788&q=80");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.auth-wrapper {
	background: white;
	height: 100vh;
	
	.auth-body {
		width: 360px;
	}
	
	.auth-heading {
		font-size: 1.5rem;
	}
	
	.auth-option {
		font-size: 0.875rem;
		> a {
			color: $theme-text-color-secondary
		}
	}

	.forgot-password {
		> a {
			color: $theme-text-color-secondary
		}
	}

	input:focus {
		box-shadow: none;
	}
}

@media (max-width: 767.98px) { 
	.auth-background-col {
		display: none;
	}	
}